<template>
  <div id="excel">
    <div class="excel-wrapper">
      <button @click="addInputPair">추가</button>
      <button @click="createExcel">엑셀로 저장</button>
    </div>

    <div id="input_container">
      <div v-for="(input, index) in inputs" :key="index" class="row-wrapper">
        <input v-model="input.title" type="text" class="title-shell" placeholder="타이틀">
        <input v-model="input.value" type="text" class="value-shell" placeholder="값">
        <button @click="deleteRow(index)" class="delete-button">삭제</button>
      </div>
    </div>

    <div class="ad-container">
      <Adsense
        data-ad-client="ca-pub-6699354447815429" 
        data-ad-slot="8230887469"
        data-ad-format="horizontal"
        data-full-width-responsive="true">
      </Adsense>
    </div>
  </div>
</template>

<script>
// import { useMeta } from 'vue-meta'
import { inject } from 'vue'

export default {
  setup() {
    const setMeta = inject('setMeta')
    setMeta({
      title: 'SoSoCEO - 사업하는 개발자',
      keywords: 'sosoceo,엑셀,excel,예제,개발,it,코딩,chatgpt,챗지피티,노코드,로우코드,코드',
      description: '간단한 HTML 엑셀 기능 예제입니다.',
      'og:title': 'SoSoCEO - 사업하는 개발자',
      'og:description': '간단한 HTML 엑셀 기능 예제입니다.',
      'og:image': 'https://geek9.kr/img/logo_big.e0186dc8.jpg',
      'og:url': 'https://geek9.kr/excel',
      'canonical': 'https://geek9.kr/excel'
    })
    // useMeta( {
    //   title: 'SoSoCEO - 사업하는 개발자',
    //   link: [{rel: "canonical", href: 'https://geek9.kr/excel'}],
    //   meta: [
    //     { vmid: 'charset_excel', charset: 'utf-8' },
    //     { vmid: 'http-equiv_excel', 'http-equiv': 'X-UA-Compatible', content: 'IE=edge,chrome=1' },
    //     { vmid: 'company_excel', name: 'company', itemprop: 'name', content: 'GEEK9' },
    //     { vmid: 'keywords_excel', name: 'keywords', content:'sosoceo,엑셀,excel,예제,개발,it,코딩,chatgpt,챗지피티,노코드,로우코드,코드' },
    //     { vmid: 'viewport_excel', name: 'viewport', content: 'width=device-width' },
    //     { vmid: 'description_excel', name: 'description', content: '간단한 HTML 엑셀 기능 예제입니다.' },
    //     { vmid: 'theme-color_excel', name: 'theme-color', content: '#ffa07a'},
    //     { vmid: 'og:site_name_excel', property: 'og:site_name', content: 'GEEK9'},
    //     { vmid: 'og:description_excel', property: 'og:description', content: '간단한 HTML 엑셀 기능 예제입니다.'},
    //     { vmid: 'og:title_excel', property: 'og:title', content: 'SoSoCEO - 사업하는 개발자'},
    //     { vmid: 'og:image_excel', property: 'og:image', content: 'https://geek9.kr/img/logo_big.e0186dc8.jpg'},
    //     { vmid: 'og:url_excel', property: 'og:url', content: 'https://geek9.kr/excel'}
    //   ]
    // })
    return {
    }
  },
  mounted() {
    this.api.setPage('excel')
    console.log(this.api.getCookies('page'))
    this.$emit("setMenu", {page:'excel'})
  },
  data() {
    return {
      inputs: [{ title: '', value: '' }]
    }
  },
  methods: {
    addInputPair() {
      this.inputs.push({ title: '', value: '' })
    },
    deleteRow(index) {
      this.inputs.splice(index, 1)
    },
    createExcel() {
      // 입력된 값 가져오기
      var inputs = document.querySelectorAll('.title-shell, .value-shell')
      var isMissingValue = false
      inputs.forEach(function(input) {
        if (!input.value.trim()) {
            isMissingValue = true
            return false // 중단
        }
      })

      if (isMissingValue) {
        alert("값이 입력되지 않은 input이 있습니다.")
        return; // 중단
      }

      // 엑셀 시트 데이터
      var data = []

      inputs.forEach(function(input, index) {
        if (index % 2 === 0) { // 타이틀 input
          data.push([input.value])
        } else { // 값 input
          data[data.length - 1].push(input.value)
        }
      })

      // 엑셀 워크북 생성
      var wb = window.XLSX.utils.book_new()
      var ws = window.XLSX.utils.aoa_to_sheet(data)

      // 스타일 적용
      for (var r = 0; r < data.length; r++) {
        for (var c = 0; c < data[r].length; c++) {
            var cellAddress = {r: r, c: c}
            var cellRef = window.XLSX.utils.encode_cell(cellAddress)
            var style = {}
            // 타이틀 셀
            if (c % 2 === 0) {
              style.fill = {fgColor: {rgb: "FFFF00"}} // 노란색 배경
            }
            // Value 셀
            else {
                style.fill = {fgColor: {rgb: "FFFFFF"}} // 흰색 배경
            }
            ws[cellRef].s = style
        }
      }

      // 파일 저장
      window.XLSX.utils.book_append_sheet(wb, ws, "Sheet1")
      window.XLSX.writeFile(wb, 'dynamic_inputs.xlsx')
    }
  }
};
</script>

<style>

#excel {
  height: 100vh;
  width:100%;
  margin: 0;
}
.row-wrapper {
  margin-bottom:5px;
}
.row-wrapper > button {
  padding:5px;
}
.excel-wrapper {
  margin-bottom:10px;
}
/* 타이틀 shell 스타일 */
.title-shell {
  width:auto;
  background-color: yellow;
  padding: 5px;
  margin-right: 5px;
}

/* Value shell 스타일 */
.value-shell {
  width:auto;
  padding: 5px;
  margin-right: 5px;
}

/* 짝수 번째 Value shell 배경색을 노란색으로 지정 */
.value-shell:nth-child(even) {
  background-color: yellow;
}

</style>